export const paths = {
   home: '',
   maintenance: 'maintenance',
   reload: 'reload',
   download: 'download',
   login: 'login',
   csvDownload: 'csv-download',
   emailConfirm: 'email-confirm/:key',
   emailUnsubscribe: 'email-unsubscribe/:key/:type',
   sysadmin: 'sysadmin',
   sysadmins: {
      masterFile: 'master-files',
      masterFiles: {
         companies: 'companies',
         divisions: 'divisions',
         divisionsFilter: 'divisions/:companyId',
         users: 'users',
         usersFilter: 'users/:companyId',
         certificates: 'certificates',
         solidsoftCredentials: 'solidsoft-credentials',
         certificatesHistory: 'certificates-history/:divisionId',
         decommissionReasons: 'decommission-reasons',
         terminals: 'terminals',
         taskNames: 'task-names',
         products: 'products',
         productsFilter: 'products/:whlsName',
         nmvsErrors: 'nmvs-errors',
         wholesalers: 'wholesalers',
         wholesalersFilter: 'wholesalers/:prdCode',
         apiKeys: 'api-keys',
         ipGuard: 'ip-guard',
      },
      maintenance: 'maintenances',
      maintenances: {
         testpack: 'testpack',
         statuses: 'statuses',
         pm2log: 'pm2-log',
         dictionary: 'dictionary',
         translation: 'translation',
         systemLog: 'system-log',
         auditLog: 'audit-log',
         parameter: 'parameters',
         development: 'development',
         developments: {
            migration: 'migration',
            applicationVersion: 'application-version',
            validationDocuments: 'validation-documents',
            portalVersions: 'portal-versions',
         },
         user: 'users',
         users: {
            divisions: 'divisions',
            divisionsFilter: 'divisions/:companyId/:divisionId',
            divisionsId: 'divisions/:id',
            rights: 'rights',
            rightsUserId: 'rights/:userId',
            manager: 'manager',
            managerFilter: 'manager/:divId',
         },
      },
      communication: 'communication',
      communications: {
         contacts: 'contacts',
         helpdesk: 'helpdesk',
         helpdeskFilter: 'helpdesk/:msgId',
         news: 'news',
         companyEmail: 'company-email',
         broadcasts: 'broadcasts',
      },
      statistic: 'statistics',
      statistics: {
         postgres: 'postgres',
         transactionNumbers: 'transaction-numbers',
         recordNumbers: 'record-numbers',
         alerts: 'alerts',
         statuses: 'statuses',
         runtime: 'runtime',
         nmvs: 'nmvs',
         batchNumber: 'batch',
         batchesOfTask: 'batches-of-task',
         recordProcTimes: 'record-proc-times',
      },
      search: 'search',
      searches: {
         datamatrix: 'datamatrix',
         recordsData: 'records-data',
         recordsFilter: 'records-data/:taskId/:divId',
         recordsFilterDate: 'records-data/:fromDate/:toDate/:toDate',
         tasks: 'tasks',
         tasksFilter: 'tasks/:sId/:divId',
         sessions: 'sessions',
         sessionsFilter: 'sessions/:termId',
         alerts: 'alerts',
         alertsFilter: 'alerts/:fromDate/:toDate/:statRsn',
         transactions: 'transactions',
         transactionsFilterDate: 'transactions/:fromDate/:toDate',
         transactionsFilter: 'transactions/:clientId/:compId/:divId',
         solidsoftTransactions: 'solidsoft-transactions',
         solidsoftTransactionsFilterDate: 'solidsoft-transactions/:fromDate/:toDate',
         divisions: 'divisions',
         certificates: 'certificates',
         solidsoftCredentials: 'solidsoft-credentials',
         users: 'users',
         userDivisions: 'user-divisions',
         userRights: 'user-rights',
         managers: 'managers',
         contacts: 'contacts',
         tasknames: 'task-names',
      },
   },
   admin: 'admin',
   admins: {
      masterFile: 'master-files',
      masterFiles: {
         divisions: 'divisions',
         users: 'users',
         terminals: 'terminals',
         certificates: 'certificates',
         solidsoftCredentials: 'solidsoft-credentials',
         taskNames: 'task-names',
         apiKeys: 'api-keys',
         ipGuard: 'ip-guard',
      },
      maintenance: 'maintenances',
      maintenances: {
         managerSettings: 'manager-settings',
         auditLog: 'audit-log',
         userDivisions: 'user-divisions',
         userRights: 'user-rights',
         manager: 'manager',
         managerFilter: 'manager/:managerId',
      },
      communication: 'communication',
      communications: {
         contacts: 'contacts',
         helpdesk: 'helpdesk',
         helpdeskFilter: 'helpdesk/:msgId',
         news: 'news',
      },
   },
   masterFile: 'master-files',
   masterFiles: {
      managedUsers: 'managed-users',
      decommissionReasons: 'decommission-reasons',
      nmvsErrors: 'nmvs-errors',
      products: 'products',
      productsFilter: 'products/:whlsName',
      terminals: 'terminals',
      wholesalers: 'wholesalers',
      wholesalersFilter: 'wholesalers/:prdCode',
   },
   statistic: 'statistics',
   statistics: {
      transactionNumbers: 'transaction-numbers',
      recordNumbers: 'record-numbers',
      runtime: 'runtime',
      alerts: 'alerts',
      statuses: 'statuses',
      nmvs: 'nmvs',
      batchNumbers: 'batch',
      batchesOfTask: 'batches-of-task',
   },
   history: 'history',
   histories: {
      users: 'users',
      userRights: 'user-rights',
      managers: 'managers',
      products: 'products',
   },
   search: 'search',
   searches: {
      datamatrix: 'datamatrix',
      recordsData: 'records-data',
      recordsFilter: 'records-data/:taskId',
      recordsFilterDate: 'records-data/:fromDate/:toDate',
      tasks: 'tasks',
      tasksFilter: 'tasks/:sId',
      sessions: 'sessions',
      sessionsFilter: 'sessions/:termId',
      alerts: 'alerts',
      alertsFilter: 'alerts/:fromDate/:toDate/:statRsn',
      transactions: 'transactions',
      transactionsFilterDate: 'transactions/:fromDate/:toDate',
   },
   settings: 'settings',
   downloadRelease: 'download-release',
   scheduledMaintenance: 'scheduled-maintenance',
   resendRecords: 'resend-records',
};
