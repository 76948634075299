import { AppComponent } from '../app.component';

const csvSysadminDataQueries = [
   { _id: '/sysadmin/search/sessions', menu: 'SYSADMIN > Search > Data queries > Sessions' },
   { _id: '/sysadmin/search/records-data', menu: 'SYSADMIN > Search > Data queries > Records' },
   { _id: '/sysadmin/search/tasks', menu: 'SYSADMIN > Search > Data queries > Tasks' },
   {
      _id: '/sysadmin/search/transactions',
      menu: 'SYSADMIN > Data queries > Search > Transactions',
   },
   { _id: '/sysadmin/search/alerts', menu: 'SYSADMIN > Search > Data queries > Alerts' },
   { _id: '/sysadmin/search/datamatrix', menu: 'SYSADMIN > Search > Datamatrix' },
   { _id: '/sysadmin/search/divisions', menu: 'SYSADMIN > Search > Master files > Divisions' },
   {
      _id: '/sysadmin/search/certificates',
      menu: 'SYSADMIN > Search > Master files > Certificates',
   },
   { _id: '/sysadmin/search/users', menu: 'SYSADMIN > Search > Master files > Users' },
   { _id: '/sysadmin/search/contacts', menu: 'SYSADMIN > Search > Contacts' },
   {
      _id: '/sysadmin/search/user-divisions',
      menu: 'SYSADMIN > Search > Maintenance > User divisions',
   },
   { _id: '/sysadmin/search/user-rights', menu: 'SYSADMIN > Search > Maintenance > User rights' },
   { _id: '/sysadmin/search/managers', menu: 'SYSADMIN > Search > Maintenance > Managers' },
   { _id: '/sysadmin/master-files/companies', menu: 'SYSADMIN > Master files > Companies' },
   { _id: '/sysadmin/master-files/divisions', menu: 'SYSADMIN > Master files > Divisions' },
   { _id: '/sysadmin/master-files/certificates', menu: 'SYSADMIN > Master files > Certificates' },
   { _id: '/sysadmin/master-files/users', menu: 'SYSADMIN > Master files > Users' },
   { _id: '/sysadmin/master-files/terminals', menu: 'SYSADMIN > Master files > Terminals' },
   { _id: '/sysadmin/master-files/products', menu: 'SYSADMIN > Master files > Products' },
   { _id: '/sysadmin/master-files/wholesalers', menu: 'SYSADMIN > Master files > Wholesalers' },
   {
      _id: '/sysadmin/master-files/decommission-reasons',
      menu: 'SYSADMIN > Master files > Decommission reasons',
   },
   { _id: '/sysadmin/master-files/nmvs-errors', menu: 'SYSADMIN > Master files > NMVS errors' },
   { _id: '/sysadmin/maintenances/audit-log', menu: 'SYSADMIN > Maintenance > Audit log' },
   { _id: '/sysadmin/maintenances/system-log', menu: 'SYSADMIN > Maintenance > System logs' },
   {
      _id: '/sysadmin/maintenances/users/divisions',
      menu: 'SYSADMIN > Maintenance > Users > Divisions',
   },
   {
      _id: '/sysadmin/maintenances/users/rights',
      menu: 'SYSADMIN > Maintenance > Users > User rights',
   },
   {
      _id: '/sysadmin/maintenances/users/manager',
      menu: 'SYSADMIN > Maintenance > Users > Manager',
   },
   { _id: '/sysadmin/statistics/postgres', menu: 'SYSADMIN > Statistics > Postgres' },
   {
      _id: '/sysadmin/statistics/transaction-numbers',
      menu: 'SYSADMIN > Statistics > Transaction numbers',
   },
   {
      _id: '/sysadmin/statistics/record-numbers',
      menu: 'SYSADMIN > Statistics > Record numbers',
   },
   { _id: '/sysadmin/statistics/statuses', menu: 'SYSADMIN > Statistics > Statuses' },
   { _id: '/sysadmin/statistics/alerts', menu: 'SYSADMIN > Statistics > Alerts' },
   { _id: '/sysadmin/statistics/runtime', menu: 'SYSADMIN > Statistics > Runtime' },
   { _id: '/sysadmin/statistics/nmvs', menu: 'SYSADMIN > Statistics > NMVS Error codes' },
   { _id: '/sysadmin/statistics/batches-of-task', menu: 'Statistics > Batches of task' },
   { _id: '/sysadmin/statistics/batch', menu: 'SYSADMIN > Statistics > Batch Number' },   
];

const csvAdminDataQueries = [
   { _id: '/admin/maintenances/audit-log', menu: 'Admin > Maintenance > Audit log' },
];

const csvDataQueries = [
   { _id: '/search/sessions', menu: 'Search > Sessions' },
   { _id: '/search/records-data', menu: 'Search > Records' },
   { _id: '/search/tasks', menu: 'Search > Tasks' },
   { _id: '/search/transactions', menu: 'Search > Transactions' },
   { _id: '/search/alerts', menu: 'Search > Alerts' },
   { _id: '/statistics/transaction-numbers', menu: 'Statistics > Transaction numbers' },
   { _id: '/statistics/record-numbers', menu: 'Statistics > Record numbers' },
   { _id: '/statistics/statuses', menu: 'Statistics > Statuses' },
   { _id: '/statistics/alerts', menu: 'Statistics > Alerts' },
   { _id: '/statistics/runtime', menu: 'Statistics > Runtime' },
   { _id: '/statistics/batches-of-task', menu: 'Statistics > Batches of task' },
   { _id: '/statistics/batch', menu: 'Statistics > Batch Number' },
   { _id: '/statistics/nmvs', menu: 'Statistics > NMVS Error codes' },
   { _id: '/master-files/products', menu: 'Master files > Products' },
   { _id: '/master-files/wholesalers', menu: 'Master files > Wholesalers' },
   { _id: '/history/users', menu: 'Histories > Users' },
   { _id: '/history/user-rights', menu: 'Histories > User rights' },
   { _id: '/history/managers', menu: 'Histories > Managers' },
   { _id: '/history/products', menu: 'Histories > Products' },
   // { _id: '/master-files/nmvs-errors', menu: 'Master files > NMVS errors' },
];

export function getCsvMenus(): any {
   let options = csvDataQueries;
   if (AppComponent.isRight('admin')) {
      options = options.concat(csvAdminDataQueries);
   }
   if (AppComponent.isRight('sys')) {
      options = options.concat(csvSysadminDataQueries);
   }
   return options;
}
